<template>
    <div class="breadcrumb-container">
        <div v-for="(item, index) in store" :key="item.id">
            <div
                class="breadcrumb-item"
                :class="{ 'no-hover': index === store.length - 1 }"
                @click="index === store.length - 1 ? null : goTo(item.link)"
            >
                <i
                    class="fas fa-angle-right"
                    v-if="index !== 0"
                    :style="
                        index !== store.length - 1 ? null : 'color: #ef7b1d'
                    "
                ></i>
                <span
                    :style="
                        index !== store.length - 1 ? null : 'color: #ef7b1d'
                    "
                    >{{ item.name }}</span
                >
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ClassicBreadCrumb",
    async created() {
        await this.keepPath()
    },
    watch: {
        routePath: {
            async handler(value) {
                if (this.routePath !== value) {
                    this.routePath = value
                    await this.keepPath()
                }
            },
        },
    },
    props: {
        title: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            store: [],
            routePath: this.$route.path,
        }
    },
    methods: {
        async keepPath() {
            // Créer une copie profonde du store pour ne pas le modifier directement
            this.store = await JSON.parse(
                JSON.stringify(
                    this.$store.getters["breadCrumb/getClassicBreadCrumb"]
                )
            )

            let newObject = {
                name: this.title,
                link: this.$route.path,
                routeName: this.$route.name,
            }

            for (let i = 0; i < this.store.length; i++) {
                if (this.store[i].name === newObject.name) {
                    this.store.length = i
                }
            }

            this.store.push(newObject)
            this.$store.commit("breadCrumb/setClassicBreadCrumb", this.store)
        },

        goTo(link) {
            this.$router.push(link)
        },
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_loader.scss";
@import "@/assets/scss/variables/fontSize.scss";

.breadcrumb-container {
    display: flex;
    flex-wrap: wrap;
    text-wrap: nowrap;
    align-items: center;
    gap: 0.5em;

    .breadcrumb-item {
        display: flex;
        align-items: center;
        gap: 0.5em;
        font-size: $normal;
        transition: 0.15s all;

        &:hover {
            cursor: pointer;
            color: $orange-neutral;
            transition: 0.15s all;
        }

        &::before {
            display: none;
        }
    }
}

.no-hover {
    cursor: default !important;
}
</style>
